import React from 'react';
import { graphql } from 'gatsby';
import PageWrapper from "../components/PageWrapper";
import Hero from "../sections/snorefreeswiss/Hero";
import Features from "../sections/snorefreeswiss/Features";
import ContentBite from "../sections/snorefreeswiss/Content_bite";
import ContentAboutUs from "../sections/snorefreeswiss/Content_about_us";
import ContentHowItWorks from "../sections/snorefreeswiss/Content_how_works";
import Cta1 from "../sections/snorefreeswiss/Cta";
import HeadHelmet from '../components/HeadHelmet';
import NewsList from "../sections/news/newslist";

// News
import imgNewsUrl1 from "../assets/image/snorefreeswiss/bite-cleaned.jpg";
import PricingTable from '../sections/snorefreeswiss/PricingTable';
const newsUrl1 = '/news/how-to-clean-bite'

const IndexPage = () => {
  
  return (
    <>
      <PageWrapper
        headerConfig={{
          theme: "dark",
          align: "right",
          isFluid: true,
          button: 'null', // cta, account, null
          //buttonText: t('hp_button_cta'),
        }}
        footerConfig={{
          style: "style_snore", //style1, style2
        }}
      > 
        <HeadHelmet 
          title='web_title' 
          description='web_description'
        />
        <Hero />
        {/* <Services /> */}
        {/* <Testimonial1 /> */}
        <ContentBite />
        <Features />
        <ContentHowItWorks />
        <ContentAboutUs />
        {/* <Fact /> */}
        <PricingTable linkpricing />
        <NewsList news={[
          // { title: 'Confronto tra tutti i sistemi contro l\'apnea notturna', imageUrl: imgNews1, publishDate:'2023-10-01T12:00:00Z' }, 
          { title: 'news1_title', imageUrl: imgNewsUrl1, newsUrl: newsUrl1, publishDate: '2024-04-28T12:00:00Z' }
        ]} />
        <Cta1 />
        {/* <CaseStudies /> */}
        {/* <Testimonial2 /> */}
        {/* <Cta2 /> */}
      </PageWrapper>
    </>
  );
};
export default IndexPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
