import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { graphql } from 'gatsby';

import imgW from "../../assets/image/svg/l6-footer-wave.svg";
import imgWD from "../../assets/image/svg/l6-footer-wave-dark.svg";
import imgP from "../../assets/image/l6/png/l6-footer-pattern.png";
import { Link } from 'gatsby-plugin-react-i18next';

const PricingTable = (param) => {
  const { t } = useTranslation();
  const { linkpricing } = param
  return (
    <>
      {/* <!-- Pricing Area --> */}
      <div className="pricing-section bg-default-2 pt-14 pt-lg-24 pb-23 pb-lg-31 position-relative gr-z-index-1">
        <Container>
          {/* <Row className="justify-content-center">
            <Col md="8" lg="7" xl="6">
              <div className="section-title text-center mb-12 mb-lg-17">
                <h2 className="title gr-text-3 mb-7 heading-color">Pricing made easy</h2>
                <p className="px-4 gr-text-8 mb-0 text-color-opacity">
                  With lots of unique blocks, you can easily build a page easily
                  without any coding.{" "}
                </p>
              </div>
            </Col>
          </Row> */}
          <Row className="justify-content-center">
            <Col
              sm="10"
              md="6"
              lg="5"
              xl="4"
              className="mb-9"
              data-aos="fade-right"
              data-aos-duration="500"
            >
              {/* <!-- .pricing card --> */}
              <div className="pricing-card bg-gray-2 gr-hover-shadow-1 text-center pt-9 pb-9 pr-9 pr-xl-9  pl-9 pl-xl-9 border rounded-10">
                <div className="price-content light-mode-texts">
                  <span className="small-title gr-text-12 text-uppercase text-red fw-bold">
                    {t('pricing_tab1_title')}
                  </span>
                  <div className="d-flex align-items-end justify-content-center mt-9 ">
                    <span className="currency me-2 gr-text-6 text-blackish-blue fw-bold line-spacing-none">
                      
                    </span>
                    <h2 className="price-value gr-text-2 fw-bold line-spacing-none mb-0 text-blackish-blue">
                      {t('pricing_tab1_price')}
                    </h2>
                    <span className="per gr-text-9 text-blackish-blue">
                      
                    </span>
                  </div>
                  <ul className="card-list list-style-border text-center ps-0 mt-9 mb-3 mx-auto">
                    <li className="text-black gr-text-9 border-top mb-0 py-7 d-block">
                      {t('pricing_tab1_b1')}
                    </li>
                    <li className="text-black gr-text-9 border-top mb-0 py-7 d-block">
                      {t('pricing_tab1_b2')}
                    </li>
                    <li style={{ height: '100px' }}  className="text-black gr-text-9 border-top mb-0 py-7 d-block">
                      <i>{t('pricing_tab1_b3')}</i>
                    </li>
                    {/* <li className="text-black gr-text-9 border-top mb-0 py-7 d-block">
                      {t('pricing_tab1_b4')}
                    </li> */}
                    
                  </ul>
                </div>
                <Link
                  to="/contact"
                  className="btn btn-red rounded-8 text-white gr-min-width-219">
                  {t('pricing_tab1_cta')}
                </Link>
              </div>
              {/* <!-- /.pricing carde Ends--> */}
            </Col>
            <Col
              sm="10"
              md="6"
              lg="5"
              xl="4"
              className="mb-9"
              data-aos="fade-right"
              data-aos-duration="500"
            >
              {/* <!-- .pricing card --> */}
              <div className="pricing-card gr-hover-shadow-1 gr-box-shadow-4 text-center pt-9 pb-9 pe-9 pe-xl-9  ps-9 ps-xl-9 bg-white rounded-10">
                <div className="price-content light-mode-texts">
                  <span className="small-title gr-text-12 text-uppercase text-red fw-bold">
                    {t('pricing_tab3_title')}
                  </span>
                  <div className="d-flex align-items-end justify-content-center mt-9 ">
                    <span className="currency me-2 gr-text-6 text-blackish-blue fw-bold line-spacing-none">
                      CHF
                    </span>
                    <h2 className="price-value gr-text-2 fw-bold line-spacing-none mb-0 text-blackish-blue">
                      {t('pricing_tab3_price')}
                    </h2>
                    <span className="per gr-text-9 text-blackish-blue ">

                    </span>
                  </div>
                  <ul className="card-list list-style-border text-center pl-0 mt-9 mb-3 mx-auto">
                    <li className="text-black gr-text-9 border-top mb-0 py-7 d-block">
                      {t('pricing_tab3_b1')}
                    </li>
                    <li className="text-black gr-text-9 border-top mb-0 py-7 d-block">
                      {t('pricing_tab3_b2')}
                    </li>
                    <li style={{ height: '100px' }} className="text-black gr-text-9 border-top mb-0 py-7 d-block">
                      <i>{t('pricing_tab3_b3')}</i>
                    </li>
                    {/* <li className="text-black gr-text-9 border-top mb-0 py-7 d-block">
                      {t('pricing_tab3_b4')}
                    </li> */}
                  </ul>
                </div>
                <Link to="/contact"
                  className="btn btn-black rounded-8 gr-min-width-219">
                  {t('pricing_tab3_cta')}
                </Link>
              </div>
              {/* <!-- /.pricing carde Ends--> */}
            </Col>
            {linkpricing &&
              <Link
                to="/pricing"
                className="btn-link with-icon gr-text-blue gr-text-9 fw-bold mt-10"
              >
                {t('pricing_title')}<i className="fas fa-arrow-right"></i>
              </Link> 
            }

          </Row>
        </Container>
        <div className="wave-shape gr-abs-br">
          <img src={imgW} alt="" className="w-100 light-shape default-shape" />
          <img src={imgWD} alt="" className="w-100 dark-shape" />
        </div>
        {/* <div
          className="pattern-shape gr-abs-br gr-z-index-n1"
          data-aos="fade-left"
          data-aos-duration="500"
          data-aos-delay="400"
        >
          <img src={imgP} alt="" />
        </div> */}
      </div>
    </>
  );
};

export default PricingTable;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

