import React from 'react';
import { Link, useTranslation } from 'gatsby-plugin-react-i18next';
import { graphql } from 'gatsby';
import { Container, Row, Col } from "react-bootstrap";

import imgC1 from "../../assets/image/snorefreeswiss/card_user.png";
// import imgC2 from "../../assets/image/telemedicine/png/content-1-img2.png";
import imgCS from "../../assets/image/snorefreeswiss/bite-snoring-custom-magnet-tr.png";

const Content1 = () => {
  const { t } = useTranslation();

  return (
    <>
      {/* <!-- Content1 Area --> */}
      <div className="content-section pt-11 pt-lg-20 pb-10 pb-lg-0 bg-default-6">
        <Container>
          <Row className="align-items-center justify-content-center">
            <Col
              xs="11"
              sm="10"
              md="9"
              lg="6"
              className="mb-6 mb-md-13 mb-lg-0"
            >
              <div
                className="double-image-group"
                data-aos="zoom-in"
                data-aos-duration="1200"
              >
                {/* <div className="abs-image-1 responsive-scaling-1 gr-z-index-n1"> */}
                  <div className="animation-item">
                    <img
                      className="main-img w-100 rounded-8"
                      src={imgCS}
                      width={400}
                      alt=""
                      id="an-item-2p1"
                    />
                  </div>
                {/* </div> */}

                {/* <div className="abs-image-2 responsive-scaling-2 gr-z-index-1">
                  <div className="animation-item">
                    <img
                      className="main-img"
                      src={imgC1}
                      alt=""
                      id="an-item-2p1"
                      style={{ borderWidth: 1, borderStyle: 'solid', borderRadius: 8 }}
                    />
                  </div>
                </div> */}
                {/* <div className="animation-item gr-abs-center gr-z-index-1">
                  <img src={imgCS} alt="" width={400} />
                </div> */}
              </div>
            </Col>
            <Col sm="10" md="9" lg="6" xl="5" className="offset-xl-1">
              <div className="content-text pe-lg-10">
                <h2 className="gr-text-4 mb-9 heading-color">
                  {t('content1_title')}
                </h2>
                <p className="gr-text-9 mb-0 text-color-opacity">
                  {t('content1_body')}
                </p>
                <Link
                  to="/why-do-we-snore"
                  className="btn-link with-icon gr-text-blue gr-text-9 fw-bold mt-10"
                >
                  {t('content1_cta')}<i className="fas fa-arrow-right"></i>
                </Link>
                <br/>
                <Link
                  to="/anti-snore-bite-explained"
                  className="btn-link with-icon gr-text-blue gr-text-9 fw-bold mt-10"
                >
                  {t('snore_bite_title')}<i className="fas fa-arrow-right"></i>
                </Link>

                <Link
                  to="/pricing"
                  className="btn-link with-icon gr-text-blue gr-text-9 fw-bold mt-10"
                >
                  {t('pricing_title')}<i className="fas fa-arrow-right"></i>
                </Link>


              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Content1;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

